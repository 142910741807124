@import 'variables.css';

#aboutMe{
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.col-2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;

    height: auto;
}

.padding-6-up{
    padding-top: 6rem;
    padding-bottom: 6rem;
}
.padding-6-side{
    padding-left: 6rem;
    padding-right: 6rem;
}

.about-me-photo{
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: var(--border-radius);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

  /* Mobile Styles */
@media (max-width: 768px) {

    #aboutMe{
        margin-top: 100px;
    }

    .padding-6-side{
        padding-right: 0;
        padding-left: 0;
    }

    .padding-6-up{
        padding-top: 2rem;
        padding-bottom: 2rem;
    }

    .col-2{
        grid-template-columns: 1fr;
        gap: 1rem;
    }

}