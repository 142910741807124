@import 'variables.css';

.top-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
  
  .portfolio {
    display: flex;
    flex-direction: column;
  }
  
  #filter {
    display: flex;
    margin: 2rem 0;
    gap:1em;
    position: relative;
  }

  .filter-background{
    position: absolute;
    height: 100%;
    border-radius: 20px;
    z-index: -1;
    transition: all .6s .125s cubic-bezier(0.1, 0.75, 0.25, 1);
    background-color: var(--primary-color);
  }

  .filter-dropdown{
    display: none;
  }

  .filter-btn {
    position: relative;
    padding: 0 20px;
    display: flex;
    height: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 30px;
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    transition: color 0.5s ease 0.2s;
  }
  
  .filter-btn:hover{
    color: var(--link-color);
    background: none;
  }

  .filter-btn.active {
    color: var(--super-light-color);
    background: none;
  }

/* Toggle container */
  .toggle-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    border-radius: 20px;
    position: relative;
    font-size: 1rem;
    font-weight: bold;
    width: 350px;
    height: 30px;
    justify-content: center;
  }
  
  
  .toggle-option {
    flex: 1;
    text-align: center;
    text-transform: uppercase;
    z-index: 1;
    cursor: pointer;
  }
  
  .toggle-option:hover {
    color: var(--link-color);
  }
  
  .toggle-switch {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 0;
  }
  
  .toggle-indicator {
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: var(--secondary-color);
    border-radius: 20px;
    transition: all .6s .125s cubic-bezier(0.1, 0.75, 0.25, 1);
  }
  
  .toggle-switch.highlight-mode .toggle-indicator {
    transform: translateX(0);
  }
  
  .toggle-option.active{
    pointer-events: none;
    cursor: pointer;
  }
  
  .toggle-switch.gallery-mode .toggle-indicator {
    transform: translateX(100%);
  }

  .bottom-container{
    margin: 2em 0;
  }
  
  .gallery {
    display: flex;
    flex-direction: column;
    text-align: start;
    justify-content: center;
  }

  .gallery .item{
    display: flex;
    flex-direction: row;
    gap: 5rem;
    transition: opacity 1s ease-in-out;
    padding: 4rem;
    cursor: pointer;
  }

  .gallery .item:nth-child(3n+1){
    justify-content: center;
  }

  .gallery .item:nth-child(3n+2){
    justify-content: end;
  }

  .gallery .item:nth-child(3n+3){
    flex-direction: row-reverse;
    text-align: end;
    justify-content: start;
  }

  .photo{
    display: flex;
    justify-content: center;
    height: 550px;
    width: 40%;
    overflow: hidden;
    border-radius: 10px;
  }
  
  .photo img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    transition: all 3s .125s cubic-bezier(0.1, 0.75, 0.25, 1);
  }

  .gallery .item:hover .photo img{
    transform: translateY(-20px) scale(1.1);
    cursor: pointer;
  }

  .gallery .item:hover .btn-circle::before{
    transform: scale(1.1) rotate(180deg) translateX(-8px);
  }

  .gallery .item:hover .btn-circle{
    background-color: var(--secondary-color);
    border: none;
    transform: scale(1.1);
  }
  .gallery .item:hover .btn-text {
    color: var(--primary-color);
    transform: translateX(8px);
  }

  .details >*{
    margin: 0;
  }

  .details >* p{
    margin: 0;
  }

  .details{
    display: flex;
    width: 30%;
    flex-direction: column;
    justify-content: space-around;
  }

  .highlights-grid{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 3 equal-width columns */
    gap: 10px;
    /* column-count: 3;
    column-gap: 10px; */
    padding: 100px 0;
    animation: fadeIn 0.5s ease-in-out forwards;
  }

  .highlights-grid div img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: 0; 
    animation: fadeIn 0.5s ease-in-out forwards; 
    /* animation-delay: calc(0.2s * var(--index)); */
  }

 
/* 
  .photo:hover img {
    width: 100%;
  } */

  .gallery .item:hover h3 {
    color: var(--link-color);
  }

  .details{
    display: flex;
    flex-direction: column;
  }
  


/* .fade-in {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.fade-out {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
} */


    /* Mobile Styles */
    @media (max-width: 768px) {

      #portfolio-page{
        margin-top: 100px;
        padding-top: 0;
      }

      #title-text{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: sticky;
        margin-bottom: 40px;
      }
      #title-text h1 {
        font-size: 30px;
        margin: 0;
        opacity: 1;
        transition: opacity 0.5s ease-in-out;
        margin-bottom: 25px;
      }
      
      .portfolio {
        display: flex;
        flex-direction: column;
      }

      #filter {
        display: none;
      }

      .filter-btn{
        width: 100%;
        padding: 0;
        font-weight: bold;
        color: var(--link-color);
      }

      .filter-dropdown{
        display: block;
        margin-top: 2rem;
        width: 100%;
      }

      .filter-dropdown-btn {
        font-size: 1rem;
        /* font-weight: bold; */
        width: 100%;
        text-transform: uppercase;
        background-color: var(--primary-color);
        color: var(--super-light-color);
        display: flex;
        justify-content: space-between;
        padding: 10px 20px;
      }

      .filter-dropdown-btn:after {
        content: '';
        height: 15px;
        width: 20px;
        display: inline-block;
        align-self: center;
        background-image: url('../../public/assets/dropdown-arrow.svg');
        background-size: contain;
        background-repeat: no-repeat;
      }

      .filter-dropdown-menu{
        background-color: var(--secondary-color);
        color: var(--primary-color);
        border-radius: var(--border-radius);
        padding: 0.5rem 0;
        margin-top: 5px;
        text-transform: uppercase;
        text-align: start;
      }

      .filter-dropdown-item{
        padding: 10px;
        border-bottom: 0.5px solid var(--super-light-color);
      }

      .filter-dropdown-item:last-child{
        border-bottom: none;
      }


      .filter-dropdown-item:hover{
        background-color: var(--primary-color);
        color: var(--super-light-color);
      }

      .toggle-container{
        margin-top: 2em;
        font-weight: normal;
      }

      .gallery {
          display: flex;
          flex-direction: column;
          gap: 4em;
          text-align: start;
          justify-content: center;
      }

      .bigNumber{
        font-size: 50px;

      }

      .toggle-container{
        width: 100%;
      }

      .highlights-grid{
        column-count: 2;
        column-gap: 10px;
        padding: 2em 0;
      }


      .details{
        width: 100%;
      }
      .details div{
        padding: 1rem 0;
      }

      .photo{
        width: 100%;
        height: 250px;
      }

      .gallery .item{
        padding: 0;
        gap: 2rem;
      }

      .gallery .item:nth-child(3n+1), .gallery .item:nth-child(3n+2), .gallery .item:nth-child(3n+3){
        flex-direction: column;
      }

    }