@import 'variables.css';

.work {
  padding: 6em 0em 10em 0em;
  position: relative;
}

.work-content{
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100svh;
}

.work-content::before, .work-content::after {
  content: '';
  position: absolute;
  width: 50%;
  height: 1px;
  background-color: var(--secondary-color);
  z-index: -1;
}

.work-content::before {
  top: 0;
  right: 0;
}

.work-content::after {
  bottom: 0;
  left: 0;
}

.top-line::before {
  content: '';
  position: absolute;
  height: 100%;
  top: 0;
  width: 1px;
  background-color: var(--secondary-color);
}

.work-item {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  padding: 3em 7em;
  justify-content: center;
}

.work-text p{
  margin: 0;
}



/* amna */

  
  .work-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 1;
    width: 40%;
    text-align: left;
    align-items: flex-start;
    position: relative;
  }

  .row-reverse .work-text {
    text-align: right;
    align-items: flex-end;
  }

  .row-reverse{
    flex-direction: row-reverse;
  }

  .work-image {
    display: flex;
    justify-content: center;
    width: 50%;
    overflow: hidden;
    border-radius: var( --border-radius);
  }
  
  .work-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .work-image img, .work-text {
    position: relative;
    cursor: pointer;
    transition: all 3s .125s cubic-bezier(0.1, 0.75, 0.25, 1);
  }
  
  .work-item:hover .work-image img{
    transform: translateY(-20px) scale(1.1);
  }
  
  .work-item:hover .work-text{
    transform: translateY(-20px); 
  }
  
  
  .inquire-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: transparent;
    border: 1px solid #333;
    padding: 0.5rem 1rem;
    text-transform: uppercase;
    font-size: 0.9rem;
    cursor: pointer;
    text-decoration: none;
    color: #333;
  }
  
  .inquire-button .arrow {
    font-size: 1rem;
  }
  


  .bigNumber {
    margin: 0;
    font-size: 9rem;
    font-family: var(--font-primary);
    line-height: 1;
  }


  .bottom-line {
    position: relative;
    height: 1px;
    background-color: var(--lightest-color);
    margin: 1rem 0;
  }

  .work-item:nth-child(odd) .bottom-line {
    width: 100%;
    margin-left: auto;
  }

  .work-item:nth-child(even) .bottom-line {
    width: 100%;
    margin-right: auto;
  }


  
 /* Mobile Styles */
 @media (max-width: 768px) {

  .work{
    padding:0;
    padding-bottom: 2em;
  }

  .work-item{
    flex-direction: column;
    padding: 1em 2em;
  }

  .work-content::before, .work-content::after {
    display: none;
  }

  .work-image, .work-text{
    width: 100%;
  }
  
  .row-reverse .work-text {
    align-items: flex-start;
    text-align: start;
  }

  .top-line::before{
    display: none;
  }

  .work-content::before, .work-content::after{
    width: 100%;
  }

}
