@import 'variables.css';

.App {
  text-align: center;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


body{
  overflow: hidden;
}

.row{
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* For Other Sections */
header{
  opacity: 0;
}
/* uncomment for effect */
/* .hero-bg-image{
  opacity: 0;
} */

.hero {
  opacity: 1;
}


/* uncomment for effect */
/* .hero-content {
  top: 10%;
  transform: scale(2);
  transition: all .6s .125s cubic-bezier(0.1, 0.75, 0.25, 1);
} */

.App.loaded .hero-content {
  transform: scale(1);
  top: 0;
  transition: all .6s .125s cubic-bezier(0.1, 0.75, 0.25, 1);
}



.App.loaded header {
  opacity: 1;
  transition: opacity .6s .125s cubic-bezier(0.1, 0.75, 0.25, 1);
}

.hero-content1, .hero-content6, .hero-content5{
  opacity: 0;
}

.App.loaded .hero-content6,
.App.loaded .hero-content5,
.App.loaded .hero-content1{
  opacity: 1;
  transition: opacity .6s .125s cubic-bezier(0.1, 0.75, 0.25, 1);
  /* transition: opacity 3s ease-in-out; */
}


.App.loaded .hero-bg-image {
  opacity: 1;
  transition: opacity .6s .125s cubic-bezier(0.1, 0.75, 0.25, 1);
  /* transition: opacity 3s ease-in-out; */
}



@media (max-width: 768px) {

  /* .hero-content {
    transform: scale(1.2);
  }

  .App.loaded .hero-content{
    transform: scale(0.8);
    transition: transform 1s .125s cubic-bezier(0.1, 0.75, 0.25, 1);
  } */

  
}