
/* Fonts */

@font-face {
  font-family: 'TT Drugs';
  src: url('../assets/fonts/TTDrugs/TTDrugs-Regular.woff2') format('woff2'),
      url('../assets/fonts/TTDrugs/TTDrugs-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Dream Avenue';
  src: url('../assets/fonts/DreamAvenue/DreamAvenue.woff2') format('woff2'),
      url('../assets/fonts/DreamAvenue/DreamAvenue.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Thesignature';
  src: url('../assets/fonts/Thesignature/Thesignature.woff2') format('woff2'),
      url('../assets/fonts/Thesignature/Thesignature.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Add this to any elements using TT Drugs */
.font-weight-fix {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.grecaptcha-badge {
  display: none !important;
}


/* root start */

 body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "kern", "liga";
  -webkit-appearance: none; 
  appearance: none;
  -webkit-text-decoration-skip: objects; 
  -webkit-tap-highlight-color: transparent;
  background-image: url('../../public/assets/grain.avif');
  background-color: #F8F5F2;
  background-repeat:repeat;
  background-size: contain;
  color: var(--primary-color);
  font-family: var(--font-secondary);
  opacity: 1;
  /* transition: all .6s cubic-bezier(0.1, 0.75, 0.25, 1); */
}


:root {
  --padding: 20px;
  --margin: 20px;
  --font-primary: 'Dream Avenue', serif;
  --font-secondary: 'TT Drugs', sans-serif;
  --font-cursive: 'Thesignature';
  --link-color: #C48160;
  /* --primary-color: #3E3F3B; */
  /* --primary-color: #595B52; */
    --primary-color: #4B4D44;
  --secondary-color: #D2D4C7;
  --lightest-color: #F8F5F2;
  --super-light-color: #F8F5F2;
  --header-height: 80px;
  --subtext-padding: 40px;
  --border-radius: 0.6rem;
  --nav-padding: 5em;
}

a{
  color: var(--link-color);
  cursor: pointer;
  appearance: none;
  -webkit-text-decoration-skip: objects; /* Ensures text decoration is consistent */
  -webkit-tap-highlight-color: transparent;
}
a:hover{
  color: var(--primary-color);
}

h1{
  font-size: 8rem;
  font-family: var(--font-primary);
  font-weight: normal;
  margin: 0;
  line-height: normal;
}

h2{
  font-size: 4rem;
  font-family: var(--font-primary);
  text-transform: uppercase;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 5px;
  line-height: normal;
}

h3{
  font-size: 2.25rem;
  font-family: var(--font-primary);
  text-transform: uppercase;
  font-weight: normal;
  margin:0;
}

h4, .small-text, .subtext{
  font-family: var(--font-secondary);
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: normal;
}

p{
  line-height: 1.4;
  font-size: 1rem;
  letter-spacing: 0.5px;
  font-family: var(--font-secondary);
  font-weight: normal;
}

.cursive {
  font-family: var(--font-cursive);
  text-transform: none;
  line-height: 0.5;
  font-size: 3rem;
  margin-top: 0;
  margin-bottom: 1rem;
}

section{
  padding: 10em;
  overflow: hidden;
}

a{
  text-decoration: none;
}

.no-padding{
  padding: 0;
}

.margins{
  margin: 10em;
}


.no-margins{
  margin: 0;
}

button{
  font-family: var(--font-secondary);
  font-size: 0.9rem;
  text-transform: uppercase;
  color: var(--primary-color);
  background-color: var(--secondary-color);
  border-radius: 20px;
  padding: 10px 50px;
  border: none;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.22, 0.665, 0.34, 0.875);
}

button:hover{
  color: var(--secondary-color);
  background-color: var(--primary-color);
}

.arrow-btn::before{
  content: '';
  display: inline-block;
  padding: 50px;
  border-radius: 50%;
  background-color: var(--lightest-color);
}
.arrow-btn::after{
  padding: 30px;
  content: '';
  transform: rotate(180deg);
  display: inline-block;
  background-image: url('../../public/assets/arrow-black.svg');
  background-size: contain;
  background-repeat: no-repeat;
}

.spacer-2em{
  padding: 2em 0;
}

.subtext{
  position: relative;
  text-transform: uppercase;
  font-family: var(--font-secondary);
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  text-orientation: mixed;
  white-space: nowrap;
  margin: 0;
  height: 0;

}

.subtext.left{
  top: 140px;
  align-self: flex-start;
  left: var(--subtext-padding);
}

.subtext.right{
  bottom: 140px;
  align-self: flex-end;
  right: var(--subtext-padding);
}

.custom-link {
  position: relative;
  display: inline-block;
  font-family: var(--font-secondary);
  color: var(--link-color);
  padding: 15px 5px;
  text-decoration: none;
  /* letter-spacing: 1.5px; */
  font-size: 1rem;
  text-transform: uppercase;
  transition: all .6s .125s cubic-bezier(0.1, 0.75, 0.25, 1);
  cursor: pointer;
}

.custom-link:hover {
  color: var(--primary-color);
  padding-left: 30px;
}

.custom-link::after {
  content: '';
  display: inline-block;
  width: 16px; /* Adjust based on your SVG size */
  height: 16px; /* Adjust based on your SVG size */
  margin-left: 10px; /* Space between the link text and the arrow */
  background-image: url('data:image/svg+xml;utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23d0793d" class="bi bi-arrow-right" viewBox="0 0 16 16"%3E%3Cpath fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 1 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/%3E%3C/svg%3E');
  background-size: contain;
  background-repeat: no-repeat;
  transition: opacity .4s .25s, transform .6s .25s;
  transition-timing-function: cubic-bezier(0.1, 0.75, 0.25, 1);
  vertical-align: bottom;
  
}

.custom-link::before {
  content: '';
  display: inline-block;
  width: 16px; /* Adjust based on your SVG size */
  height: 16px; /* Adjust based on your SVG size */
  opacity: 0;
  position: absolute;
  left: -18px; /* Position before the text, adjust as needed */
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 1 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  transition: opacity .4s .25s, transform .6s .25s;
  transition-timing-function: cubic-bezier(0.1, 0.75, 0.25, 1);
  vertical-align: bottom;
}

.custom-link:hover::after {
  opacity: 0;
  transform: translateX(20px); /* Move the SVG to the left */
}

.custom-link:hover::before {
  opacity: 1;
  transform: translateX(20px); /* Move the SVG to the left */
}


.unstyled-link{
  font-family: var(--font-secondary);
  color: var(--secondary-color);
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  padding-bottom: 2px;
  /* border-bottom: 0.5px solid var(--secondary-color); */
  transition: color 0.5s ease;
}

.unstyled-link:hover{
  color: var(--link-color);
}

.arrow {
  border-radius: 50%;
  cursor: pointer;
  transition: all .6s;
  transition-timing-function: cubic-bezier(0.1, 0.75, 0.25, 1);
  border: 0;
  z-index: 2; /* Ensure buttons are clickable */
  
}

.arrow img {
  width: 60px;
  height:55px;
  padding: 25px;
  transition: filter 0.6s ease-in;
}

.arrow.right {
  transform: rotate(180deg);
}

.arrow:hover {
  border: 1px solid var(--primary-color);
}


.arrow.left:hover {
  transform: translateX(20px); 
}

.arrow.right:hover {
  
  transform: translateX(-20px) rotate(180deg); 
}

.rotating-logo{
  z-index: 10;
  position: relative;
  height: 150px;
  width: 150px;
}


.camera {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  width: 100%;
  height: 100%;
}

.text {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  transform-origin: center;
  animation: revolve 12s linear infinite;
}

@keyframes revolve {
  from {
    transform: translateX(-50%) rotate(0deg);
  }
  to {
    transform: translateX(-50%) rotate(360deg);
  }
}

.accent-btn{
  background-color: transparent;
  border: none; 
  position: relative;
  cursor: pointer;
  align-items: center;
  gap: 2px;
  display: flex;

}


.accent-btn .btn-text {
  font-family: var(--font-secondary);
  font-size: 0.8rem;
  text-transform: uppercase;
  color: var(--primary-color);
  transition: transform 0.5s ease;
}

.accent-btn .btn-circle {
  width: 60px;
  height: 60px;
  border: 1px solid var(--primary-color);
  border-radius: 50%;
  position: relative;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  transition: transform 0.5s ease;
}
.accent-btn:hover .btn-circle {
  background-color: var(--secondary-color);
  border: none;
  transform: scale(1.1);
}
.accent-btn .btn-circle::before {
  content: ''; /* Remove text arrow */
  width: 60px;
  height: 60px;
  background-image: url("../../public/assets/arrow-black.svg");
  background-size: 24px; /* Size of the SVG */
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}

.accent-btn:hover .btn-circle::before {
  transform: scale(1.1) rotate(180deg); /* Animate arrow forward */
}

.accent-btn:hover .btn-text {
  color: var(--primary-color);
  transform: translateX(8px);
}

.text-animate {
  opacity: 0;
  transform: translateY(20px);
  will-change: opacity, transform; /* Improves performance */
}

/* animations */
/* @keyframes fadeUp {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-up {
  opacity: 0;
  transform: translateY(60px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  will-change: opacity, transform;
}

.fade-up.visible {
  opacity: 1;
  transform: translateY(0);
}
 */



  /* Mobile Styles */
  @media (max-width: 768px) {

    .rotating-logo{
      width: 100px;
      height: 100px;
    }

    h1{
      font-size: 3rem;
    }

    h2{
      font-size: 2.5rem;
    }

    h3{
      font-size: 1.5rem;
    }

    h4{
      font-size: 1rem;
      margin: 1rem 0;
    }

    p{
      font-size: 1rem;
    }
    
  section{
    margin:0;
    padding: 4rem 2rem;
  }

  .margins{
    margin: 2em;
  }

  .spacer-2em{
    padding: 1em 0;
  }

  button{
    -webkit-tap-highlight-color: transparent;
    appearance: none;
    color: var(--primary-color);
  }
  .accent-btn .btn-circle {
    width: 50px;
    height: 50px;
  }
  .accent-btn .btn-circle::before {
    width: 50px;
    height: 50px;
    background-size: 25px;
    
  }

  .arrow img {
    width: 40px;
    height:37px;
    padding: 10px;
    transition: filter 0.6s ease-in;
  }

  .notFound{
    margin-top: var(--header-height);
  }

  .subtext{
    display: none;
  }

  .subtext.heading{
    all: unset;
    display: block;
    font-size: 0.8rem;
    font-family: var(--font-secondary);

    text-transform: uppercase;
    /* margin: 1rem 0; */
    padding: 0 2em;
    text-align: start;
    display: none;
  }

  .cursive{
    font-size: 2rem;
    line-height: normal;
    font-family: var(--font-primary);
    text-transform: uppercase;
}

  .subtext.services, .subtext.testimonial{
    position: static;
    padding-top: 3em;
  }

  .custom-link{
    font-size: 1rem;
  }

}

