/* Header.css */
@import 'variables.css';

.header {
  z-index: 99;
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--header-height);
  position: fixed;
  left: 0;
  top: 0;
  background: transparent;
  transition: all 1s ease;
}

.nav-container.hidden{
  transform: translateY(calc(-1 * var(--header-height)));
  padding-bottom: 0em;

}

.headerlogo.mobile{
  display: none;

}
  
.nav-container{
  width: 100%;
  height: 100%;
  /* padding: 2rem 0; */
  display: flex;
  justify-content: center;
  transition: all 0.3s ease;
  background-color: #F8F5F2;
  background-image: url('../../public/assets/grain.avif');
  background-color: #F8F5F2;
  background-repeat:repeat;
  background-size: contain;
  border-bottom: 0.5px solid var(--secondary-color);
  /* padding: 1em 0; */
  /* padding: 3em 0; */
  /* padding: calc(var(--header-height)/2); */
}

.nav-container.transparent {
  background-color: transparent;
  background: none;
  border-bottom: 0px;
  /* border-bottom: none; */
  padding-top: 3em;
  /* padding-bottom: 0em; */
  /* padding-bottom: 0; */
}
/* .header--scrolled {
  height: 60px;
  position: fixed;
} */


nav{
  text-transform: uppercase;
}


  .header--scrolled .hamburger {
    display: block;
  } 

  /* .nav-grid{
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    width: 100%;
  } */

  nav{
    display: flex;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
  }

  
  /* .nav-left {
    display: flex;
    justify-self: end;
  }

  .nav-right {
    display: flex;
    justify-self: start; 
  } */

  .headerlogo{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-self: center;
    margin: 0 1rem;
    position: absolute;
    left: 1rem;
  }
  .headerlogo img{
    height: 40px;
  }
  .header-nav-item a{
    font-size: 0.9rem;
  }
  .header-nav-item{
    font-size: 0.9rem;
    padding: 5px 20px;
    border-radius: 20px;
    transition: all ease 0.4s;
    cursor: pointer;
    position: relative;
    color: var(--primary-color);
    display: flex;
    align-items: center;
  }

  .header-nav-item::after{
    content: '';
    display: inline-block;
    opacity: 0;
    width: 12px; 
    height: 12px; 
    background-image: url('../../public/assets/orange-arrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.6s cubic-bezier(0.22, 0.665, 0.34, 0.875);
    right: 0px;
}

.header-nav-item.background{
  background-color: var(--secondary-color);
}

.header-nav-item.background::after{
  display: none;
}

.header-nav-item.background:hover{
  background-color: var(--primary-color);
  color: var(--lightest-color);
}


.header-nav-item:hover::after{
  opacity: 1;
  transform: translateX(5px);
}
  
.header-nav-item:hover{
    color: var(--link-color);
  }
  
  nav:last-child {
    margin-right: 0;
  }
  
  nav a {
    text-decoration: none;
    position: relative;
    color: unset;
    line-height: normal;
  }

  .social-group{
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 0 25px;
    margin-left: 15px;
  }

  .social-link{
    border-radius: 50%;
    display: flex;
    align-items: center;
    will-change: transform;
    transition: all .6s cubic-bezier(0.1, 0.75, 0.25, 1);
  }

  .social-link:hover{
    transform: scale(1.3);
  }
  
  .social-link img{
    width: 25px;
    height: auto;
  }


  .hamburger {
    position: fixed;
    cursor: pointer;
    z-index: 1000;
    right: 30px;
    top: 30px;
    padding: 5px 20px;
    border-radius: 20px;
    transition: all ease 0.4s;
    background-color: var(--secondary-color);
  }

  .hamburger.hidden {
    display: none;
  }

  .hamburger:hover{
    color: var(--lightest-color);
    background-color: var(--primary-color);
  }

  .side-nav-menu {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    top: 0;
    right: 0;
    width: 90%;
    height: 100%;
    background: url('../../public/assets/green-bg.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1000;
    transform: translateX(100%);
    transition: all 0.7s cubic-bezier(0.23, 1, 0.32, 1);
    transition-property: transform;
    
  }

  .side-nav-menu.is-active {
    transform: translateX(0%);
  }

  .side-nav-menu nav {
    flex-direction: column;
    padding: 50px 80px;
    gap: 20px;
    align-items: end;
  }

  .side-nav-item{
    padding: 0;
    border-radius: 0;
  }


  .side-nav-link:hover, .portfolio-nav:hover, .portfolio-nav.open{
    font-style: italic;
    -webkit-text-stroke: 1px var(--lightest-color);
    color: transparent;
    background: none;
  }

  .side-nav-item::after{
    display: none;
}

.side-nav-item {
    color: var(--lightest-color);
    font-family: var(--font-primary);
    font-size: 4rem;
    margin: 0;
    transition: color 0.7s cubic-bezier(0.23, 1, 0.32, 1);
  }

  .social-links.light{
    color: var(--lightest-color);
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .social-links.light .instagram::after{
    background-image: url('../../public/assets/social-icons/instagram-white.svg');
  }
  .social-links.light .facebook::after{
    background-image: url('../../public/assets/social-icons/facebook-white.svg');
  }
  .social-links.light .pinterest::after{
    background-image: url('../../public/assets/social-icons/pinterest-white.svg');
  }
  
  .close-btn {
    background: none;
    width: 45px;
    height: 45px;
    border: 1px solid var(--lightest-color);
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    transition: border-color 0.3s ease;
    padding: 0;
    margin-bottom: 3em;
  }

  .close-btn span {
    display: block;
    position: absolute;
    width: 60%;
    height: 1px;
    background-color: var(--lightest-color);
    transition: all 0.3s ease;
    left: 20%;
    top: 50%;
  }

  .close-btn span:nth-child(1) {
    transform: translateY(-50%) rotate(45deg);
  }

  .close-btn span:nth-child(2) {
    transform: translateY(-50%) rotate(-45deg);
  }

  .close-btn:hover span:nth-child(1) {
    transform: translateY(-4px);
    top: 50%;
  }

  .close-btn:hover span:nth-child(2) {
    transform: translateY(2px);
    top: 50%;
  }

.watermark-container{
  position: absolute;
    left: 50px;
    gap: 2em;
    bottom: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.watermark-container .rotating-logo .camera{
    transform: translate(-50%, -50%);
}

.hamburger.show {
  display: block; 
}


/* Dropdown Styling */
.portfolio-nav {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  cursor: pointer;
}

.nav-dropdown {
  position: absolute;
  right: 100%;
  margin-right: 50px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  transform: translateX(50%); /* Start off-screen */
  opacity: 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Add transition to visibility */
  text-align: start;
  pointer-events: none;
}

.nav-dropdown.open {
  opacity: 1;
  transform: translateX(0);
  pointer-events: auto; /* Enable pointer events when open */
}

.nav-dropdown.close {
  opacity: 0;
  transform: translateX(50%); /* Move it off-screen */
  pointer-events: none; /* Disable pointer events */
}

.nav-dropdown a:hover{
  color: var(--super-light-color)
}

.side-nav-item.portfolio-nav:hover > .nav-dropdown{
  -webkit-text-stroke: unset !important;

  color: var(--primary-color);
  font-style: normal;
  background: none; 
}

.nav-dropdown a {
  padding: 10px;
  color: var(--primary-color);
  font-style: normal;
}


/* Show hamburger on mobile */
@media (max-width: 768px) {

  .header {
    align-items: center;
    justify-content: space-between;
    z-index: 5;
    position: fixed;
    height: 60px;
    background-image: url('../../public/assets/grain.avif');
    background-color: #F8F5F2;
    background-repeat:repeat;
    background-size: contain;
    border-bottom: 0.5px solid var(--secondary-color);
  }

  .hamburger, .hamburger.hidden {
    display: block;
    position: static;
    font-size: 1rem;
    margin: 0 1.5rem;
    background: none;
    border: 0.5px solid var(--primary-color);
  }

  .headerlogo.mobile{
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: static;
    padding: 0;
    margin: 0 1.5rem;
    opacity: 1;
    transition: opacity 0.2s ease;
  }

  .headerlogo.mobile.transparent{
    opacity: 0;
  }

  .headerlogo.mobile img{
    height: 25px;
  }

  .nav-container{
    display: none;
  }

  .close-btn{
    width: 30px;
    height: 30px;
    margin: 1rem 0;
  }

  .side-nav-item{
    font-size: 2.2rem;
  }

  .side-nav-menu nav {
    padding: 2rem;
  }

  .side-nav-menu{
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .watermark-container{
    position: relative;
    top: 0;
    left: 0;
    flex-direction: column-reverse;
    gap: 2rem;
    padding: 0 2rem 2rem 0;
  }

  .social-links.light a{
    flex-direction: row-reverse;
  }

  .social-links.light a::after{
    opacity: 1;
    margin-right: 8px;
  }

  /* .portfolio-nav{
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  .nav-dropdown {
    position: relative;
    right: 0;
    margin: 0;
    padding: 0;
    gap: 20px;
    text-align: end;
    transform: translateX(50%); 

  }

  .nav-dropdown a {
    padding: 0;

  }

  .nav-dropdown.close{
    height: 0;
  }

  .nav-dropdown.open{
    height: auto;
    margin-top: 20px;
  } */

  

}




