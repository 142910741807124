/* contact.css */
@import 'variables.css';


.portfolio-item{
  margin-top: var(--header-height);
  padding-top: var(--nav-padding);
  width: 100%;
}

.portfolio-banner{
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 4;
    position: relative;
    align-items: center;
    padding-top: 25px;
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards;
}

.portfolio-banner h1,.portfolio-banner p{
    position: relative;
    max-width: 55%;
    margin-bottom: 10px;
}

  .image-gallery {
    column-count: 3;
    column-gap: 15px;
    padding: 50px 15px;

  }
  
  .image-gallery img {
    width: 100%;
    margin-bottom: 15px;
    break-inside: avoid;
    object-fit: cover;
    opacity: 0; 
    animation: fadeIn 0.5s ease-in-out forwards; 
    /* animation-delay: calc(0.2s * var(--index)); */
  }
  
  

  .portfolio-item .logo{
    z-index: 10;
    left: 50px;
    right: 0;
    position: relative;
  }

  .portfolio-item .navigation-buttons{
    position: relative;
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    flex-direction: row;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  
  /* Mobile Styles */
  @media (max-width: 768px) {

    .portfolio-item{
      margin-top: 80px;
      padding-top: 0;
    }

    .portfolio-banner h1, .portfolio-banner p{
      max-width: 80%;
    }

    .image-gallery{
      column-count: 2;
      column-gap: 15px;
      padding: 50px 15px;
    }

    .image-gallery img:nth-child(3n+2) {
      margin-top: 0;
    }
    
  }
  