@import 'variables.css';



.section-background{
  width: 100%;
  height: 500px;
  background-image: url('../../public/assets/hero-landscapes/featured/3.webp'); 
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  z-index: -1;
  margin: 2em 0;
  filter: grayscale(1);
}

.worldMap{
  width: 100%;
  height: 300px;
  background-image: url('../../public/assets/map2.svg'); 
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.grid-row-container{
  display: grid;
  margin: 4em 0;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  justify-items: center;
  
}

.why-choose-us-section{
  text-align: start;
}

.why-choose-us-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: start;
}

.why-choose-us-list li {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.why-choose-us-list p {
  margin: 0;
  margin-bottom: 0.1rem;
}

.why-choose-us-title{
  font-weight: bold;
}

.why-choose-us-description{
  font-size: 0.9rem;
}

.checkmark:before {
  display: inline-block;
  content: '';
  background-image: url('../../public/assets/checkmark.svg');
  width: 20px;
  height: 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 1rem;
}

.service-area-section{
  text-align: start;
}


/* Ensure the path is visible and set to initially be hidden */
.world-map path {
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 5s ease-in-out; /* Control speed and timing here */
}


.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
}

.grid-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  object-fit: cover;
}

/* Base fade styles */



.filter-container.light .filter-btn{
  transition: color 0.5s ease;
}

.filter-container.light .filter-background{
  background-color: var(--secondary-color);
}

.filter-container.light .filter-btn.active{
  color: var(--primary-color);
}

.pricing-content {
  position: relative;
  overflow: hidden;
  min-height: 400px;
  text-align: start;
}

.package-item h4{
  margin-top: 0;
  font-weight: bold;
}

.package-item{
  margin-bottom: 3em;
}

.pricing-details {
  position: absolute;
  width: 100%;
}

/* .process{
  display: flex;
  flex-direction: column;
  align-items: center;
} */

.steps{
  column-count: 4;
  column-gap: 15px;
  padding: 4rem 1rem;
}

.steps div{
  break-inside: avoid;
}
.steps div h4{
  font-weight: bold;
}


/* .faq-section{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq-title{
  margin-bottom: 6rem;
}

.faq-list{
  width: 80%;
}

.faq-item{
  cursor: pointer;
  margin-bottom: 25px;
  width: 100%;
  position: relative;
}

.faq-item:hover .horizontalSeperator::after {
  width: 100%;
}

.faq-content{
  text-align: start;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}


.faq-question{
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;
}

.faq-question .download-arrow{
  position: absolute;
  left: -5rem;
  rotate: 90deg;
}

.faq-item:hover .download-arrow{
  rotate: 0deg;
  border: 1px solid var(--primary-color);
} */
.faq-item:hover .horizontalSeperator::after {
  width: 100%;
}

.faq-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faq-title {
  margin-bottom: 6rem;
}

.faq-list {
  width: 80%;
}

.faq-item {
  cursor: pointer;
  width: 100%;
  position: relative;
  padding: 10px 0;
  transition: all 0.3s ease; /* Smooth transition */
}

.faq-question {
  display: flex;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  width: 100%;
  text-align: start;
  transition: color 0.5s ease;
}

.faq-question:hover{
  /* color: var(--link-color); */
}

.faq-question h4{
  font-weight: bold;
}

/* .download-arrow {
  transition: transform 0.3s ease;
  width: 20px;
  height: 20px;
  border-top: 2px solid var(--primary-color);
  border-right: 2px solid var(--primary-color);
  transform: rotate(90deg);
} */

.faq-answer {
  width: 100%;
  overflow: hidden;
  text-align: start;
  padding: 0;  /* Ensures no extra padding when collapsed */
  padding-left: 55px;
}


.faq-question:hover .download-arrow {
  border-color: var(--primary-color);
  transform: rotate(-90deg);
}



    /* Mobile Styles */
    @media (max-width: 768px) {

      #services-page{
        margin-top: 100px;
        padding-top: 0;
      }

      .section-background{
        height: 350px;
        background-attachment: unset;
      }

      .worldMap{
        height: 250px;
      }

      .grid-row-container{
        grid-template-columns: 1fr;
        margin: 2em 0;
      }

      .filter-container.light{
        display: flex !important;
        gap: 0 !important;
        width: 100%;
      }


      
      .steps{
        padding: 1em 2em;
          column-count: 1;
      }

      .steps div{
        padding: 1em 0;
      }

      .faq-content{
        grid-template-columns: 1fr;
        gap: 10px;
      }

      .faq-title{
        text-align: center;
        margin-bottom: 2rem;
      }

      .faq-list{
        width: 100%;

      }

      .faq-question h4{
        margin: 1rem 0;
        width: 100%;
        
      }

      .faq-answer{
        padding: 0;
        width: 100%;
      }

      /* .faq-question{
        font-size: 1.2rem;
      } */

      .faq-question .download-arrow {
        background-size: 100%;
        border: none;
      }

    }