@import 'variables.css';
/* footer.css */
#Footer {
    position: relative;
    margin: 50px;
    padding: 20px 110px;
}

#Footer p {
    font-size: 1rem;
    line-height: normal;
    letter-spacing: normal;
    margin: 0;
}


.footer-links {
    position: relative; /* Ensure the links are above the overlay */
    z-index: 2;
    display: flex;
    flex-direction: row; /* Adjust as needed */
    align-items: center;
    width: fit-content;
}

.footer-links a {
    margin: 0px 20px 15px 0px; /* Adjust spacing as needed */
    text-decoration: none; /* Remove underline */
    font-family: var(--font-secondary);
    text-transform: uppercase;
    position: relative; /* Necessary for positioning the arrow */
    
    color: var(--link-color);
}


.footer-links a::after{
    content: ''; /* Arrow character */
    display: inline-block;
    opacity: 0;
    width: 12px; /* Adjust based on your SVG size */
    height: 12px; /* Adjust based on your SVG size */
    background-image: url('../../public/assets/right-arrow.svg');
    background-size: contain;
    background-repeat: no-repeat;
    transition: all 0.6s cubic-bezier(0.22, 0.665, 0.34, 0.875);
    right: 0px;
}

.footer-links a:hover::after {
    opacity: 1;
    transform: translateX(5px); /* Move the SVG to the left */
}


.footer-links a:hover {
    color: var(--primary-blue);
}


.parent {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    gap: 20px;
    }
    
.div1 {text-align: start;
        position: relative;
        display: flex;
        justify-content: space-between;}

.div3{
    /* display: flex;
    flex-direction: row; */
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    justify-content: space-between;
    align-items: flex-end;
}



.footer-heading h3{
    text-align: center;
    font-family: var(--font-primary);
    margin: 0;
}

.div2 {
    align-items: center;
    justify-content: center;
    width: 80%;
    text-align: center;
    align-self: center;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--lightest-blue);
}

.social-links a{
    padding: 8px 0;
    text-decoration: none;
    color: var(--primary-blue);
    font-family: var(--font-secondary);
    text-transform: uppercase;
    font-size: 1rem;
    display: flex;
    flex-direction: row; /* Adjust as needed */
    align-items: end;
    transition: transform .6s .125s cubic-bezier(0.1, 0.75, 0.25, 1);
    
}

.social-links a::after{
    content: '';
    display: inline-block;
    width: 20px; 
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    transition: opacity .4s .25s, transform .6s .25s;
    transition-timing-function: cubic-bezier(0.1, 0.75, 0.25, 1);
    left: 20px;
    margin-right: 22px;
    opacity: 0;
}

.social-links a:hover::after {
    opacity: 1;
    transform: translateX(10px); /* Move the SVG to the left */
}

.social-links a:hover{
    color: var(--primary-blue);
    transform: translateX(20px);
}

.social-links .instagram:after{
    background-image: url('../../public/assets/social-icons/instagram.svg');
}

.social-links .pinterest:after{
    background-image: url('../../public/assets/social-icons/pinterest.svg');
}

/* .social-links .tiktok:after{
    background-image: url('../../public/assets/social-icons/tiktok.svg');
} */

.social-links .facebook:after{
    background-image: url('../../public/assets/social-icons/facebook.svg');
}

.copyright{
    font-size: 0.6rem;
    font-family: var(--font-secondary);
    text-transform: uppercase;
}

#Footer >* p{
    text-transform: uppercase;
}

.text-end{
    text-align: end;
}
.text-start{
    text-align: start;
}

@media (max-width: 1024px) {
    
    #Footer{
        padding: 20px 50px;
    }
}

  /* Mobile Styles */
  @media (max-width: 768px) {
    
    #Footer {
        margin: 1em;
        padding: 4em 2em;
        text-align: start;
    }

    .div3 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }

    .social-links{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .copyright {
        text-align: center;
        margin-top: 30px;
        position: relative;
    }

    .footer-links{
        display: none;
    }

    .text-start, .text-end{
        text-align: center;
    }
  
  }