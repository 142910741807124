@import 'variables.css';
.intro{
  height: 50svh;
  display: flex;
  justify-content:center;
  align-items: center;
  flex-direction: column;
}

.intro-content{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 60%;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.word-container h3{
  margin: 1px;
  padding: 0 8px;
  /* font-weight: bold; */
  line-height: 1.2;
  cursor: pointer;
}

.word-container h3:hover{
  /* font-weight: bold; */
  -webkit-text-stroke: 0.8px var(--primary-color);
  color: transparent;
}

/* .word-container {
  position: relative;
} */

.hover-image {
  position: absolute;
  width: 250px;
  height: 300px;
  scale: 0;
  opacity: 1;
  pointer-events: none;
  transition: all .6s .125s cubic-bezier(0.1, 0.75, 0.25, 1);
  z-index: 100;
  
}

.hover-image.right {
  right: 6em;
  transform: translateY(0%) rotate(-20deg);
}
.hover-image.left {
  left: 6em;
  transform: translateY(0%) rotate(20deg);
}

.hover-image img {
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

.word-container:hover .hover-image.left {
  scale: 1;
  opacity: 1;
  transform: translateY(-50%) rotate(-20deg);
}

.word-container:hover .hover-image.right {
  scale: 1;
  opacity: 1;
  transform: translateY(-50%) rotate(20deg);
}

 /* Mobile Styles */
 @media (max-width: 1024px) {
 
  .intro{
    height: auto;
    padding: 5em 5em 0em 5em;
  }
  }

 /* Mobile Styles */
 @media (max-width: 768px) {

  .intro{
    height: auto;
    padding: 3em;
  }

  .word-container{
    pointer-events: none;
  }

  .word-container h3{
    padding: 0;
    margin-right: 8px;
  }

  .intro-content{
    width: 70%;
  }

}

 /* Mobile Styles */
 @media (max-width: 568px) {

  .intro-content{
    width: 100%;
  }

}
