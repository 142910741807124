@import 'variables.css';

.blog-template-section{
    width: 70%;
    justify-self: center;
    text-align: start;
    margin-top: 2em;
}

blockquote p{
    font-size: 1.2rem;
    font-family: var(--font-primary);
    font-weight: bold;
    font-style: italic;
    padding: 15px;
}

.grid-3-col{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
    padding: 3rem 0;
}

.grid-6-col{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
    padding: 3rem 0;
}
.grid-2-col{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    padding: 3rem 0;
}


.grid-3-col img, .grid-2-col img, .grid-6-col img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: var( --border-radius);
    
}

.blog-template-section li{
    line-height: normal;
    margin-bottom: 6px;
}

.blog-image {
    max-width: 80%;
    height: auto;
    display: block;
    margin: 20px auto;
    border-radius: var( --border-radius);
}

.blog-template-section h1{
    font-size: 3.5rem;
    text-transform: uppercase;
    text-align: center;
    width: 80%;
    justify-self: center;
}

.blog-template-section h2{
    font-size: 2rem;
    padding-top: 3rem;
}

.blog-template-section h3{
    font-size: 1.5rem;
}

.blog-template-section-background{
    width: 100%;
    height: 400px;
    /* background-image: url('../../public/assets/balloon.avif'); 
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat; */
    z-index: -1;
    margin: 4em 0;
    
  }

  .blog-template-section-background img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale(1);
  }

  @media (max-width: 768px) {

    .blog-template-section{
        margin-top: 50px;
        margin-bottom: 0px;
        width: auto;
        justify-self:unset;
    }

    .blog-template-section ul{
        padding-left: 1rem;
    }

    .blog-template-section-background{
        height: 350px;
        background-attachment: unset;
      }

      .blog-template-section h1{
        font-size: 2.5rem;
        text-align: start;
        width: 100%;
        justify-self: start;
    }
    
    .blog-template-section h2{
        font-size: 1.8rem;
        padding-top: 2.8rem;
    }

    .grid-3-col{
        padding: 2rem 0;
        gap: 5px;
    }

    .grid-3-col img, .grid-6-col img, .grid-2-col img{
        border-radius: 0;
        
    }

    .grid-6-col{
        grid-template-columns: 1fr 1fr 1fr;
        gap: 5px;
        padding: 2rem 0;
    }

    blockquote p{
        font-weight: normal;
        padding: 0;
    }
      
  }