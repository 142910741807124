@import 'variables.css';

#aboutSection{
    background-image: url('../../public/assets/green-bg.avif');
    background-repeat: repeat-y;
    background-size: cover;
    color: var(--super-light-color);
    position: relative;
    /* height: 100dvh; */
    background-attachment: fixed;
    display: flex;
    align-items: center;

}

.link-on-dark, .unstyled-link.dark{
    color: #FFB08A;
}

.link-on-dark:hover, .unstyled-link.dark:hover{
    color: var(--super-light-color);
}

.about-content {
    padding-top: 80px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    text-align: left;
    align-items: center;
    justify-content: center;
    gap: 40px;
    /* height: 100vh; */
    position: relative;
}


/* .portrait-photo{
    width: 20%;
} */
.profile-photo {
    position: relative;
    display: flex;
    width: 300px;
    height: 370px;
    overflow: hidden;
    border-radius: var( --border-radius);
    justify-content: center;
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-out;
}

.profile-photo img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.right-grid{
    position: relative;
    padding: 30px 30px;
    width: 50%;
    z-index: 2;
    transform: translateY(0);
    transition: transform 0.3s ease-out;
}

.right-grid .rotating-logo{
    height: 120px;
    width: 120px;
}

.btn-container{
    display: flex;
    justify-content: flex-end;
}

.about-content .btn-container .accent-btn .btn-text{
    color: var(--super-light-color);
}


.circle{
    width: 40dvw;
    height: 40dvw;
    border-radius: 50%;
    background-image: url('../../public/assets/green-bg.avif');
    background-size: cover;
    background-position: center;
    background-repeat: repeat-y;
    position: absolute;
}

.readmore {
    overflow: hidden;
    transition: height 0.3s ease, opacity 0.3s ease;
    height: 0;
    opacity: 0;
  }
  
  .readmore.visible {
    height: auto; /* This will be dynamically set via JavaScript */
    opacity: 1;
  }


  /* Mobile Styles */
@media (max-width: 768px) {

    #aboutSection{
        padding: 3rem;
        height: auto;
    }
    .about-content {
        flex-direction: column;
        height: 100%;
        position: static;
    }

    .profile-photo{
        position: static;
        width: 100%;
    }

    .right-grid{
        position: relative;
        top: 10px;
        width: 100%;
        padding: 1em 0;
    }




}