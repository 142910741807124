@import 'variables.css';

#testimonials {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-items: center;
    padding-top: 6em;
}

.badge{
    padding-top: 2rem;
}

.carousel {
    position: relative;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    overflow: hidden;
}

.carousel-wrapper {
    display: flex; 
    transition: transform 1s ease-in-out; 
    width: 100%;
}

.carousel-slide {
    flex: 0 0 100%; 
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.carousel-image {
    width: 48%;
    height: auto;
    position: relative;
}

.testimonial-photo {
    width: 700px;
    height: 400px;
    object-fit: cover;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 18px;
    will-change: transform;
    transition: transform 0.1s ease-out;
}

.testimonial-text {
    margin-left: -10%;
    border-radius: 10px;
    will-change: transform;
    transition: transform 0.1s ease-out;
    text-align: start;
    width: 40%;
    background-color: var(--super-light-color);
    padding: 2rem;
}

.next-btn{
    position: absolute;
    bottom: 10%;
    right: 0;
}

/* Mobile Styles */
@media (max-width: 1024px) {

    .badge{
        padding:2em 10em;
    }

    #testimonials {
        padding-bottom: 6em;
    }

    .testimonial-photo {
        width: 500px;
    }

}


/* Mobile Styles */
@media (max-width: 768px) {

    #testimonials{
        padding: 2em;
    }

    .badge{
        padding: 1em 2em 2em 2em;
    }

    .carousel{
        padding: 2rem 0 1rem 0;
    }

    .carousel-slide{
        display: flex;
        flex-direction: column;
    }
    .testimonial-photo {
        height: 200px;
    }
    .carousel-image {
        width: 100%;

    }
    .testimonial-text {
        margin-left: 0;
        margin: 20px 0;
        background-color: transparent;
        padding: 0;
    }
    .next-btn{
        /* bottom: 0;
        margin-top: 5px; */
        display: none;
    }

    .subtext.testimonial{
        top: 140px;
        padding-top: 3em;
    }
}