@import 'variables.css';


#sectionPin {
  overflow: hidden;
  position: relative;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

[data-scroll-container] {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.pin-wrap {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
  padding: 6rem;
}

.pin-wrap div{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.pin-wrap div p, .pin-wrap div h2 {
  width: 40%;
  text-align: start;
}

.pin-wrap > * {
  min-width: 300px;
  padding: 0 5vw;
}

.pin-wrap img {
  height: 80svh;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: var( --border-radius);
}
  

.aboutServices{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 2em;
  text-align: left;
  align-items: center;
  padding-top: 1rem;
}

.left-col {grid-area: 1 / 1 / 2 / 2;}
.right-col { grid-area: 1 / 2 / 2 / 3; }


.horizontalSeperator{
    position: relative;
    border-top: 1px solid var(--secondary-color);
    margin: 15px 0;
}

.horizontalSeperator::after {
    content: '';
    position: absolute;
    top: -1px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: var(--primary-color);
    transition: width 0.3s ease;
}

.service:hover .horizontalSeperator::after {
  width: 100%;
}

.service{
    margin: 30px 0 40px 0;
}

.service:hover{
    cursor: pointer;
}

.service:hover .service-header{
  font-style: italic;
}


.service-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.text-col{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.download-arrow{
    width: 40px;
    height: 40px;
    background-image: url('../../public/assets/arrow.webp');
    background-size: contain;
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center;
    rotate: 0deg;
    border-radius: 50%;
    border: 1px solid transparent;
    transition: all 0.5s ease;
}

.service:hover .download-arrow{
    rotate: -45deg;
    border: 1px solid var(--primary-color);
}

.hover-text{
  opacity: 0;
  line-height: 0;
  margin: 0;
  transition: all 0.2s ease-in;
  text-transform: none;
}

.service:hover .hover-text{
  opacity: 1;
  font-style: normal;
}

.text-col p{
    margin: 0;
}

.services-title {
  position: relative;
  font-size: 7.5rem;
  margin: 0;
  text-transform: none;
  /* cursor: pointer; */
}

.services-title .title-default,
.services-title .title-hover {
  transition: opacity 0.1s ease;
}

.services-title .title-hover {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  /* font-style: italic; */
  /* -webkit-text-stroke: 1px var(--primary-color); */
  /* color: transparent; */
}

.services-title:hover .title-default {
  opacity: 0;
}

.services-title:hover .title-hover {
  opacity: 1;
}

.subtext.services{
  position: absolute;
  bottom:0;
  top: 300px !important;
}

 /* Mobile Styles */
 @media (max-width: 1024px) {

  .pin-wrap img {
    height: 40svh;
  }
  
  .pin-wrap > * {
    padding: 0;
  }

  .services-title {
    font-size: 4rem;
    text-align: center;
    margin-bottom: 2rem;
  }


  .aboutServices{
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
  }

}

 /* Mobile Styles */
 @media (max-width: 768px) {

  .aboutServices{
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    /* padding: 2rem auto; */
    /* padding-top: 0; */
  }
  .left-col{
    padding-bottom: 1em;
  }
  .horizontalSeperator{
    padding: 5px 0;
    margin: 5px 0;
  }

  .services-title {
    font-size: 2.5rem;
  }

  .download-arrow{
    width: 20px;
    height: 20px;
  }

  #sectionPin{
    display: none;
  }

  .text-end, .text-start{
    color: var(--link-color);
  }

}