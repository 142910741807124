/* contact.css */
@import 'variables.css';

#contact {
    background-image: url('../../public/assets/green-bg.avif');
    background-repeat: repeat-y;
    background-size: cover;
    background-attachment: fixed;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact-content{
    padding: 50px 0px;
    background-image: url('../../public/assets/bg.avif');
    background-repeat: repeat-y;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    width: 80%;
  }

  
  .contact-content h2 {
    font-size: 2rem;
    font-family: var(--font-primary);
    text-transform: uppercase;
  }

  .contact-content p {
    width: 70%;
  }
  
  .contact-form {
    max-width: 600px;
    margin: 2em 0;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
  
.form-input,
.form-textarea,.custom-date-picker {
    width: 100%;
    padding: 15px 20px;
    margin: 10px 0;
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
    font-size: 1rem;
    font-family: var(--font-secondary);
    line-height: 1.6;
    letter-spacing: 0.5px;
    box-sizing: border-box;
}

.react-datepicker-wrapper{
    width: 100%;
    box-sizing: border-box;
}
  
.form-input:focus,
.form-textarea:focus {
    border-color: var(--primary-color);
    outline: none;
}
  
.form-textarea {
    height: 80px;
    resize: vertical;
}

  /* submit */
.form-button {
    margin: 30px 0;
    background-color: white; 
    border: 1px solid var(--primary-color);
    cursor: pointer;
    text-transform: uppercase;
    font-family: var(--font-secondary);
    font-weight: normal;
    text-transform: uppercase;
}
  
.form-button:hover {
    transform: translateY(-5px);
    color:  var(--lightest-color) !important;
    background-color: var(--primary-color);
}

.form-button-complete {
    border: none;
    color: var(--primary-color);
    background-color: var(--secondary-color);
}

.form-button:disabled {
    background-color: #ccc;
    color: var(--primary-color);
    cursor: not-allowed;
    opacity: 0.6;
}
  
.error-message {
    color: red;
    font-size: 0.8rem;
    margin-top: 20px;
    margin-bottom: -2px;
    display: block;
    text-align: left;
    padding: 0 10px;
    font-family: var(--font-secondary);
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
  }

.success-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.dropdown{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: var(--primary-color) !important;
    background-color: white;
    width: 100%;
    padding: 15px 20px;
    margin: 10px 0;
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
    font-size: 1rem;
    font-family: var(--font-secondary);
    line-height: 1.6;
    letter-spacing: 0.5px;
    box-sizing: border-box;
}

.dropdown::after {
    content: '▼';
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .dropdown option {
    padding: 10px;
    font-size: 16px;
  }

  
.checkmark-icon {
    width: 30px;
    height: 30px;
}

/* Mobile Styles */
@media (max-width: 1024px) {

    .contact-content{
        width: 100%;
      }

}
  
/* Mobile Styles */
@media (max-width: 768px) {

    .contact-content {
        width: 100%;
        padding-bottom: 0;
    }

    .contact-content p {
        width: 90%;
    }

    .form-textarea{
        height: 160px;
    }

    .contact-content h2 {
        font-size: 1.5rem;
        }
    .form-input{
        padding: 10px 15px;
        margin: 8px 0;
    }

    .contact-form {
        margin-bottom: 0;
        padding-bottom: 2em;
    }

}