/* hero.css */
@import 'variables.css';

.hero {
  height: 100svh;
  width: 100%;
  position: relative;
  text-align: center;
  position: relative;
  padding: 0;
  
}

.hero-bg-image{
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../../public/assets/hero-landscapes/featured/2.webp'); 
  background-size: cover;
  background-position: top;
  background-attachment: fixed;
  z-index: -1;
}


.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.hero-content {
  position: relative;
  z-index: 2;
  padding-top: 150px;
  display: flex;
  width: 100%;
  justify-content: center;
}

.hero-grid{
  display: flex;
  flex-direction: column;
  width: clamp(280px, 30vw, 350px);
  justify-content: center;
  gap: 10px;
  transition: all 0.5s ease;
}
  
.hero-content1 {
  text-align: start;
  
}

.hero-content5 {
  text-align: start;
}
.hero-content6 {
  font-size: 1.7rem;
  font-family: var(--font-secondary);
  font-weight: 100;
  text-transform: uppercase;
  color: var(--secondary-color);
  align-self: center;
  margin-top: 15px;
}

.small-caps{
  font-size: 0.7rem;
  font-family: var(--font-secondary);
  color: var(--primary-color);
  text-transform: uppercase;
  letter-spacing: 1.5px;
}


svg {
  max-width: 100%;
  max-height: 100%;
  will-change: transform, opacity;
  contain: strict;
}
svg path,
svg polygon {
  fill-opacity: 0;
  fill: var(--primary-color);
  stroke: var(--primary-color);
  stroke-width: 1;
  stroke-dasharray: 3319;
  stroke-dashoffset: 3319;
  animation-name: draw;
  animation-duration: 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}


.svg-light path,  .svg-light polygon{
  fill: var(--secondary-color) !important;
  stroke: var(--secondary-color) !important;
}

@keyframes draw {
  80% {
    stroke-dashoffset: 0;
    stroke-width: 1;
  }
  100% {
    stroke-width: 0;
    fill-opacity: 1;
  }
}

.row-svg{
  display: flex;
  justify-content: center;
  column-gap: 10px;
  align-items: center;
  max-height: 35px;
}

  /* Mobile Styles */
  @media (max-width: 768px) {

    .hero-bg-image {
      background-attachment:unset;
      background-image: url('../../public/assets/hero-landscapes/featured/2-mobile.avif');

    }

    .hero-grid{
      gap: 5px;
    }


    .hero-content{
      padding-top: 80px;
    }

  }


