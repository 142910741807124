@import 'variables.css';


.blogPage h1{
  margin-bottom: 2.5rem;

}

.blogPage{
  /* margin-top: var(--header-height); */
  /* margin-top: var(--header-height);
  padding-top: var(--nav-padding); */
}

.blogPage-heading{
   display: flex;
   flex-direction: column;
   align-items: center;
}
.blogPage-heading p{
  width: 70%;
}

.blogPage-heading h2{
  font-size: 2rem;
  text-transform: none;
  margin-bottom: 0;
  /* width: 70%; */
}

.blog-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    padding: 2rem 0;
    width: 80%;
    justify-self: center;
  }

  .blog-post-text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0;
  }

  .blog-post-thumbnail{

    padding: 2rem 0;
  }
  
  
  .blog-post-thumbnail img {
    width: 100%;
    height: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    object-fit: cover;
    border-radius: var( --border-radius);
    transition: transform .6s cubic-bezier(0.1, 0.75, 0.25, 1);
  }
  
  .blog-post {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--primary-color);
    cursor: pointer;
    text-align: start;
    transition: transform .6s cubic-bezier(0.1, 0.75, 0.25, 1);
  }

  .blog-post a{
    color: var(--primary-color);
    transition: color .6s cubic-bezier(0.1, 0.75, 0.25, 1);
  }



  .blog-post h3{
    /* font-size: 2rem; */
    text-transform: none;
    margin-bottom: 0;
}

    /* .blog-post-date{
      font-size: 11px;
      font-weight: bold;
    } */
  
  .blog-post:hover {
    transform: translateY(-10px);
    /* border: 1px solid var(--secondary-color); 
    background-color: var(--primary-color); */
  }

  .blog-post:hover a h3{
    /* color: var(--super-light-color); */
    color: var(--link-color);
  }

  .blog-post:hover .blog-post-thumbnail img{
    transform: scale(1.1);
  }

  @media (max-width: 768px) {

    .blogPage{
      margin-top: 100px;
      padding: 0 2em;
    }

    .blogPage h1 {
      margin-bottom: 1.5rem;
    }
    .blogPage-heading p {
      width: 100%;
  }

  .blog-post h3{
    color: var(--link-color);
}


  .blog-grid{
    grid-template-columns:auto;
    gap: 1rem;
    width: 100%;
  }

  .blog-post{
    padding: 1rem;
  }

  .blog-post-thumbnail img {
    height: 250px;

  }




  }